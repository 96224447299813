<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

// icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiButtonProps {
  animated?: boolean;
  icon?: IconDefinition | null;
  iconBefore?: boolean;
  iconClass?: string | object;
  iconRatio?: string;
}
const props = withDefaults(defineProps<CiButtonProps>(), {
  animated: false,
  icon: null,
  iconBefore: false,
  iconClass: '',
  iconRatio: '0.6',
});

export interface CiButtonSlots {
  default: any;
  icon: any;
}
const slots = defineSlots<CiButtonSlots>();

/********************
 * REFS & VARS      *
 ********************/
const defaultIcon = computed(() => {
  return props.iconBefore ? faChevronLeft : faChevronRight;
});

const iconClasses = computed(() => {
  return {
    'icon--animated': props.animated,
    'order-first': props.iconBefore,
  };
});

/********************
 * FUNCTIONS        *
 ********************/
function hasSlot(name: keyof CiButtonSlots = 'default') {
  return !!slots[name];
}
</script>

<template>
  <button type="button">
    <slot />
    <slot name="icon">
      <CiAwesomeIcon
        v-if="!hasSlot('icon')"
        :class="[{ ...iconClasses }, iconClass]"
        :icon="icon ? icon : defaultIcon"
        :ratio="iconRatio"
      />
    </slot>
  </button>
</template>
